import React from "react";
import styles from "./tabs.module.css"
const BuyPlays = () => {
  return (
    <>
       <div className="p-3">
      <h2 className={`${styles.fw_bold} ${styles.p_10} text-dark`}>
      Buy Plays using $BIG Tokens and get 10% Free PLAYS

      </h2>
      <div >
        <form className={styles.form_plays}>
        <input type="text" className={styles.inputTabs}/>
        <select className={styles.dropdown}>
          <option></option>
          <option></option>
          <option></option>
        </select>
        <input type="text" className={styles.inputTabs}/>
        </form>
        <button className={styles.submit_form}>
        Submit
        </button>
      </div>
    </div>
    </>
  );
};

export default BuyPlays;

import React from "react";
import styles from "./tabs.module.css";

const ActivateBots = () => {
  return (
    <div className="p-5">
      <h2 className={`${styles.fw_bold} ${styles.p_10} text-dark`}>
        Let the BidBot bid for you while you are away. BidBot will never let you
        lose!
      </h2>
      <div >
        <form className={styles.form_bots}>
        <input type="text" className={styles.inputTabs}/>
        <input type="text" className={styles.inputTabs}/>
        </form>
        <button className={styles.submit_form}>
          Activate Bid Bot
        </button>
      </div>
    </div>
  );
};

export default ActivateBots;

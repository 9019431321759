// import { configureStore } from "@reduxjs/toolkit";
// import userReducer from './user/userSlice'

// const store = configureStore({
//     reducer:{
//         // Multiple reducers add here
//         user: userReducer,
//     }
// })

// export default store;

// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store);

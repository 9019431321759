import * as React from 'react';
import logo from '../../assets/img/appleIphone13.jpg'
import { AiFillPlayCircle } from 'react-icons/ai';
import './home.css'
import RecentBids from '../HomeTabs/RecentBids';
import BuyPlays from '../HomeTabs/BuyPlays';
import ActivateBots from '../HomeTabs/ActivateBots';
import ProductInfo from '../HomeTabs/ProductInfo';
import user from "../../assets/img/user.svg"
import bid from "../../assets/img/bid.png"
import { Link } from 'react-router-dom'
import { useState } from 'react';
// import '../../assets/styles/main.scss'
import {  useRef } from 'react';
import video from '../../assets/video/sampl.mp4'

export default function Home() {
  const [tab, setTab] = React.useState(0)
  const seasons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33];
  const [stop, setStop] = useState(false);
  const [countDown, setCountDown] = useState(10)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);
    if (countDown < 0) {
      setCountDown(10);
    }
    return () => clearInterval(interval);
  }, [countDown])


  const videoRef = useRef();
  const handleVideo = () => {
    setStop(!stop);
    videoRef.current.play();
  };

  

  const handleBidClick = () => {
    setCountDown(10);
  };
  return (
    <div className="container">
      <div className="top_display">
        <h2 className="content_header">
          iPhone 14 Pro Max 1 TB | $2,000.00 | 1x
        </h2>
      </div>
      <div style={{ position: "relative", top: "157px", width: "100%" }}>
        <div className="row">
          <div className={`col-md-5`}>
            <div className='banner_left'>
              <div style={{ height: "380px" }}>
                {/* <img src={logo} className='w-100 banner-img' height={"350px"} /> */}

                {!stop && <button onClick={handleVideo} className='play-btn'><AiFillPlayCircle /></button>}
                <video ref={videoRef} className='w-100' height={"350px"} loop>
                  <source src={video} type="video/mp4" />
                </video>


              </div>
            </div>
            <p className='banner_announcement'>No New Bidders After $800.00</p>

            <div className={`box-footer d-flex ${true ? ' bg-color-green' : ''}`}>
              <div className="auction-bid-button pr-2 font-size-m">
                <div className="text-right">Current bid</div>
                <div className="text-right" style={{ fontWeight: "bold" }}>$336.66</div>
              </div>
              {
                true ? <Link
                onClick={handleBidClick}
                style={{textDecoration:'none'}}
              
                  className={`auction-action-circle countdown-image countdown-image-${countDown} ${true ? 'bid-button-disabled' : ''}`}
                >
                  <span className="counter-number font-din-pro-cond text-blue-color" >
                    {countDown}
                  </span>
                </Link> :
                  <img src={bid} height={120} width={"133px"} className='auction-action-circle' />
              }

              <div className="auction-bid-button ml-auto font-size-m">
                <div className="text-right">Last bidder</div>
                <div className="text-right" style={{ fontWeight: "bold" }}>JohnWick</div>
              </div>
            </div>
            <div className='plays-left'><p>992 PLAYS LEFT</p></div>

          </div>


          <div className="col-md-7 d-flex justify-content-center flex-column">
            {/* bidder tabs  */}
            <div
              className="page-content page-container"
              style={{ marginLeft: "54px" }}
            >
              <div className="row ">
                <div>
                  <div className="mb-4 d-flex justify-content-center text-center">
                    <ul className="navs nav nav-pills" role="tablist">
                      <li className="nav-item active banner_tabs">
                        <a
                          className={`nav-link text-light ${
                            tab == 0 ? "nav_link" : ""
                          }`}
                          onClick={() => setTab(0)}
                          id="home-tab"
                          data-toggle="tab"
                          href="#home2"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          {" "}
                          Recent Bids
                        </a>
                      </li>
                      <li className="nav-item banner_tabs">
                        <a
                          className={`nav-link text-light ${
                            tab == 1 ? "nav_link" : ""
                          }`}
                          onClick={() => setTab(1)}
                          id="profile-tab"
                          data-toggle="tab"
                          href="#profile2"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          {" "}
                          Buy Plays{" "}
                        </a>
                      </li>
                      <li className="nav-item banner_tabs">
                        <a
                          className={`nav-link text-light ${
                            tab == 2 ? "nav_link" : ""
                          }`}
                          onClick={() => setTab(2)}
                          id="contact-tab"
                          data-toggle="tab"
                          href="#contact2"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          Activate Bots{" "}
                        </a>
                      </li>
                      <li className="nav-item banner_tabs">
                        <a
                          className={`nav-link text-light ${
                            tab == 3 ? "nav_link" : ""
                          }`}
                          onClick={() => setTab(3)}
                          id="contact-tab"
                          data-toggle="tab"
                          href="#contact2"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          {" "}
                          Product Info{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* bid table  */}
            <div className="bider_Table">
              {tab == 0 ? <RecentBids /> : ""}
              {tab == 1 ? <BuyPlays /> : ""}
              {tab == 2 ? <ActivateBots /> : ""}
              {tab == 3 ? <ProductInfo /> : ""}
            </div>
            {/* bid in auction avatars  */}

            <div>
              <h5 className="bider_title">Bidders in auctions</h5>
              <div className="bider_auction text-center">
                {seasons.map((index) => (
                  <>
                    <img src={user} height={45} width={48} key={index} />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import styles from "./tabs.module.css";

const RecentBids = () => {
  return (
    <>
       <table class={`table ${styles.table} ${styles.borderless} text-center`}>
        <thead>
          <tr>
            <th><strong>Bid</strong></th>
            <th>User</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
          <tr>
            <td>$ 4,986</td>
            <td>Athakkar</td>
            <td>16:24:20 EST</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default RecentBids
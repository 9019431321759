import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, Login, Registration } from "../pages";
import { routes } from "../constants";
import Header from "../layouts/Header/Header";
import PrivateRoute from "./PrivateRoute";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { getToken } from "../helpers/Utils";

export default function Navigations() {
  const accessToken = getToken();
  const reduxToken = useSelector((state) => state?.userInfo?.accessToken);
  console.log("reduxToken", reduxToken);

  const [headerVisiblity, setheaderVisiblity] = useState(false);

  useEffect(() => {
 
    if (accessToken) {
      setheaderVisiblity(true);
    } else if (reduxToken) {
      setheaderVisiblity(true);
    }
  }, [reduxToken]);

  return (
    <BrowserRouter>
      {reduxToken && <Header />}
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path={routes.HOME} element={<Home />} />
        </Route>
        {/* 👈 Renders at /app/ */}

        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.REGISTRATION} element={<Registration />} />

        <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

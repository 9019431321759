import React from "react";
import styles from "./tabs.module.css";

const ProductInfo = () => {
  return (
    <>
      <div className={`${styles.borderless} ${styles.product} p-5`}>
        <h3 className={`mb-4 ${styles.fw_bold} text-dark`}>Iphone 14 Pro Max 1 TB | $2,000.00 | 1x</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur eum
          ducimus laborum reprehenderit vel ea voluptate soluta. Expedita
          facilis neque modi deleniti corporis accusantium, repellendus
          inventore ut quidem totam, nesciunt unde provident harum saepe amet et
          repellat laudantium commodi tempora vitae sapiente. Alias,
          perspiciatis aliquid modi autem iste doloribus pariatur!
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias
          maxime deserunt dignissimos quae minus ipsum magnam animi, maiores
          velit omnis soluta suscipit ipsa? Totam atque at aliquid expedita
          dolore culpa.
        </p>
      </div>
    </>
  );
};

export default ProductInfo;
